import React from "react";
import config from "~/src/config";


function App() {


    console.log('DEBUG');
    console.log(config);


    return (

        <div style={{
            padding: 25
        }}>
            error restaurant
        </div>
    );

}


export default App;


